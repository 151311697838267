<template>
  <div class="e-content">
    <div>
      <h1>Responsive Web</h1>
      <b-card id="MediaQuery" title="Media Query">
        <b-card-text>반응형 웹에서 필수적으로 사용되고 있는 것이 미디어 쿼리입니다. 화면크기 마다 각각 다르게 CSS를 적용할 수 있게 해줍니다. PC 사이즈의 SCSS를 작성한 뒤 미디어 쿼리로
          화면 넓이가 1024px보다 작아질 때 Mobile 사이즈의 SCSS를 작성합니다.
        </b-card-text>
        <b-card-text>아래와 같이 사용합니다.</b-card-text>
        <b-card
            class="blockquote-border"
            bg-variant="light"
            text-aligin="center"
            vertical-align="center">
          <b-card-text>@media only | not 미디어유형 and | , (조건문) {적용할 css}</b-card-text>
        </b-card>
        <div class="card-code">
        <pre class="px-2 d-flex align-items-center"><code class="language-CSS">
          {{ codeMediaQuery }}
          </code>
        </pre>
        </div>
        <b-card-text>위의 router.js 코드는 vue 및 vue-router를 가져와 앱에 등록합니다. main.js 파일에서 사용하기 위해 다음 코드를 내보냅니다.</b-card-text>
        <div class="card-code">
        <pre class="px-2 d-flex align-items-center"><code class="language-javascript">
          {{ codeRouterExport }}
          </code>
        </pre>
        </div>
      </b-card>

      <b-card id="ConditionalRendering" title="Conditional Rendering">
        <b-card-text>반응형 화면크기에 따라 렌더링하는 템플릿의 엘리먼트가 변경될 수 있습니다. 아래 그림은 헤더 부분의 PC 버전과 Mobile 버전의 엘리먼트 차이를 확인할 수 있습니다. 이 때
          필요한 것이 <code>v-if</code>, <code>v-show</code> 조건부 렌더링 입니다.
        </b-card-text>
        <b-card-text>
          웹과 모바일의 header 이미지입니다.
        </b-card-text>
        <div class="px-3 mb-2 clearfix">
          <b-img class="responsive-img" style="border: 1px solid black" width="200"
                 height="75" left src="@/assets/images/outcomes/webHeader.png" alt="Left image"></b-img>
          <b-img class="responsive-img" style="border: 1px solid black" width="200"
                 height="75" right src="@/assets/images/outcomes/mobileHeader.png" alt="Right image"></b-img>
        </div>
        <br/>
        <b-card-text>
          웹과 모바일 header를 브라우저 사이즈에 따라 조건부로 렌더링하기 위해 Vuex의 store를 사용합니다.
        </b-card-text>
        <h5>📁 store/index.js</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeResponsiveStoreIndex }}</code></pre>
        </div>
        <h5>📁 main.js</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeResponsiveMainJs }}</code></pre>
        </div>
        <b-card-text>
          state에 <code>isWeb</code>, <code>isMobile</code>변수를 등록하고, mutations의 <code>UPDATE_IS_WEB</code>, <code>UPDATE_IS_MOBILE</code>
          함수를
          통해 state 변경을 관리합니다.
        </b-card-text>
        <h5>📁 store/app/index.js</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{
              codeResponsiveStoreApp
            }}</code></pre>
        </div>
        <b-card-text>
          <code>handleWindowResize()</code>함수는 브라우저 사이즈 측정을 위한 것으로 사이즈가 바뀌는것을 바로 확인하기 위해 App.vue에 해당 함수를 적용합니다.
          초기 데이터 설정이 완료된 상태인 created 단계에서 <code>addEventListener</code>로 브라우저의 크기가 변경될 때마다 handleWindowResize() 함수가 실행되어
          state에 화면넓이 값과 모바일 화면 여부를 업데이트 합니다.
          handleWindowResize() 함수는 Vuex의 mutations 함수에서 commit합니다.
          <br/> <br/>
          📌 State 접근은 컴포넌트의 computed 영역 내에서 가능
          <br/>📌 Getters 접근은 컴포넌트의 computed 영역 내에서 가능
          <br/>📌 Mutations 접근은 컴포넌트의 methods 영역 내에서 가능
          <br/>📌 Actions 접근은 컴포넌트의 methods 영역 내에서 가능
        </b-card-text>
        <h5>📁 App.vue</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{
              codeResponsiveApp
            }}</code></pre>
        </div>
        <b-card-text>Header 컴포넌트에서 <code>isMobile</code>을 사용해 <code>v-if</code>로 화면 크기에 맞는 조건부 렌더링을 합니다.</b-card-text>
        <h5>📁 views/layouts/Header.vue</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{
              codeResponsiveHeader
            }}</code></pre>
        </div>
        <b-button
            class="btn btn-warning"
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-1"
            @click="visible = !visible"
        >
          예제 전체 코드
        </b-button>
        <b-collapse id="collapse-1" v-model="visible" class="mt-2">
          <div class="card-code">
              <pre class="px-2 d-flex align-items-center"><code class="language-javascript">
                {{ codeResponsiveHeaderAll }}
                </code>
              </pre>
          </div>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BImg, BButton, BCollapse, BLink} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {
  codeMediaQuery,
  codeRouterExport,
  codeResponsiveStoreIndex,
  codeResponsiveMainJs,
  codeResponsiveStoreApp,
  codeResponsiveApp,
  codeResponsiveHeader,
  codeResponsiveHeaderAll,
} from './code'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BImg,
    BButton,
    BCollapse,
    BLink,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['MediaQuery','ConditionalRendering'],
      visible: false,
      codeMediaQuery,
      codeRouterExport,
      codeResponsiveStoreIndex,
      codeResponsiveMainJs,
      codeResponsiveStoreApp,
      codeResponsiveApp,
      codeResponsiveHeader,
      codeResponsiveHeaderAll
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
  },
}
</script>

<style>

@media (max-width: 493px) {
  .responsive-img {
    width: 100%;
    min-width: 100px
  }
}
</style>